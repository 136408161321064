import React from 'react';

import { Assets } from '@assets';
import { i18n } from '@i18n';

import { Button } from '../button';
import { Text } from '../text';

export const Maintenance: React.FC = () => {
  return (
    <div className="bg-nusa-200 flex h-screen w-screen flex-col items-center justify-center space-y-7 px-4 text-center">
      <Assets.Tools />
      <Text variant="h2-36/sb">{i18n.t('maintenanceModal.heading')}</Text>
      <Text variant="body-16/m">{i18n.t('maintenanceModal.subHeading')}</Text>
      <div className="border-y border-fuji-800 border-opacity-20 p-7 text-center">
        <Text variant="subHeading-14/m">{i18n.t('maintenanceModal.emergencyMessage')}</Text>
        <a href={`tel:${i18n.t('maintenanceModal.emergencyNumber')}`}>
          <Button variant="white" className="mt-6">
            {i18n.t('maintenanceModal.emergencyNumber')}
          </Button>
        </a>
      </div>
    </div>
  );
};
