import React from 'react';

import { isAURegionSelector, useRegionStore } from 'freely-shared-stores';

import { Assets } from '@assets';
import { Button, Text } from '@components';
import { i18n } from '@i18n';
import { closeModalSelector, getTripSelector, useModalStore, useTripStore } from '@store';

export const EmailQuoteSuccess: React.FC = () => {
  const closeModal = useModalStore(closeModalSelector);
  const trip = useTripStore(getTripSelector);
  const isAU = useRegionStore(isAURegionSelector);

  return (
    <div className="flex flex-col w-full items-center justify-center space-y-5 pb-3">
      <Assets.CheckCircleOutline width={72} />
      <Text variant="h3-28/sb" className="text-left">
        {i18n.t('emailQuote.emailQuoteSend.title')}
      </Text>
      <Text variant="body-16/r" className="text-left">
        {i18n.t('emailQuote.emailQuoteSend.description')}
      </Text>

      <div className="flex w-full justify-between border-y px-3 border-fuji-300 border-opacity-30 ">
        <Text variant="body-16/m" className="py-4">
          {i18n.t('emailQuote.emailQuoteSend.quote.quoteLabel')}
        </Text>

        <Text variant="subTitle-20/sb" className="py-4">
          {trip.metaData?.quoteId}
        </Text>
      </div>
      {isAU ? (
        <Text variant="subHeading-14/r" className="py-2">
          {i18n.t('emailQuote.emailQuoteSend.quote.description')}
        </Text>
      ) : null}
      <Button variant="snow" size="md" className="w-full md:w-[38%]" onClick={closeModal}>
        {i18n.t('emailQuote.emailQuoteSend.buttonActions.done')}
      </Button>
    </div>
  );
};
