import { twMerge } from 'tailwind-merge';

import { getIconUrlSelector, useConfigStore } from 'freely-shared-stores';

import { Text } from '@components';
import { useBoostDetailsStore } from '@store';

import { TextProps } from '../../text';

export interface BenefitsProps {
  Icon?: React.FC<React.SVGProps<SVGElement>> | string;
  title?: TextProps;
  subTitle?: TextProps;
  description?: TextProps;
}

export const BenefitsItem: React.FC<BenefitsProps> = () => {
  const inclusions =
    useBoostDetailsStore(state => state.mandatoryBoostDetails?.template?.inclusions) ?? [];
  const iconUrl = useConfigStore(getIconUrlSelector);
  return (
    <>
      {inclusions.map((item, index) => (
        <div
          className={twMerge(
            'border-b grid grid-cols-[3.75rem_1fr] md:grid-cols-[5rem_1fr] py-5 first:pt-0',
            inclusions.length - 1 === index && 'border-b-0',
          )}
          key={item.title}>
          <div className="order">
            {item.iconPath && (
              <img src={iconUrl(item.iconPath)} className="h-12 w-12 md:h-16 md:w-16" />
            )}
          </div>
          <div>
            <div className="flex justify-between pb-4 md:pb-2">
              <Text variant="body-16/sb" withMarkDown>
                {item.title}
              </Text>
              <Text variant="body-16/sb"> {item.subTitle}</Text>
            </div>
            <Text className="text-fuji-800-300" variant="subHeading-14/r" withMarkDown>
              {item.description}
            </Text>
          </div>
        </div>
      ))}
    </>
  );
};
