import { FC, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { Assets } from '@assets';
import { Button, Referral, Text } from '@components';
import { i18n } from '@i18n';
import { closeModalSelector, useGlobalStore, useGuestStore, useModalStore } from '@store';
import { growsurfApi, sendAnalyticsEvent } from '@utils';

export interface ReferralModalProps {
  children?: React.ReactNode;
}

export const ReferralModal: FC<ReferralModalProps> = () => {
  const guest = useGuestStore(state => state.guest);
  const [isOpen, setIsOpen] = useState(false);
  const setIsReferralModalClosed = useGlobalStore(state => state.setIsReferralModalClosed);
  const closeModal = useModalStore(closeModalSelector);
  const [isGrowsurfInitialized, setIsGrowsurfInitialized] = useState(false);

  useEffect(() => {
    const onGrowsurfReady = () => {
      setIsGrowsurfInitialized(true);
    };
    // Check to see if GrowSurf is available
    if (!window.growsurf) {
      // When refreshing the page, Listen and wait for the Growsurf Universal Code to initialize
      window.addEventListener('grsfReady', onGrowsurfReady);
    } else {
      //growsurf is already available
      setIsGrowsurfInitialized(true);
    }
    return () => window.removeEventListener('grsfReady', onGrowsurfReady);
  }, []);

  const handleButtonClick = async () => {
    guest?.email && (await growsurfApi.addParticipant({ email: guest?.email }));
    sendAnalyticsEvent('Referral Click', {
      firstName: guest?.firstName ?? '',
      lastName: guest?.lastName ?? '',
      email: guest?.email ?? '',
    });
    setIsOpen(true);
  };

  const handleClose = () => {
    isOpen
      ? sendAnalyticsEvent('Referral url modal close')
      : sendAnalyticsEvent('Referral modal close');
    closeModal();
    setIsReferralModalClosed(true);
  };

  return (
    <div>
      <div className="absolute top-0 right-0 pt-4 pr-4">
        <button
          tabIndex={-1}
          type="button"
          className="focus:ring-nusa-200 rounded-md bg-mono-100 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
          onClick={handleClose}>
          <span className="sr-only">Close</span>
          <Assets.CloseCircleLight className="w-5 h-5" aria-hidden="true" fill="black" />
        </button>
      </div>
      <div className="flex flex-col items-center justify-center gap-4 px-4 pb-2 bg-mono-100 rounded-2xl">
        <Assets.Gift />
        <Text className="mt-2 text-center" variant="h2-36/sb">
          {i18n.t('checkout.confirmation.referral.modal.title')}
        </Text>
        <Text className="mt-2 text-center" variant="subTitle-20/r">
          {i18n.t('checkout.confirmation.referral.modal.description')}
        </Text>
        <Referral firstName={guest?.firstName} lastName={guest?.lastName} isModalOpen={isOpen} />
        <Button
          isLoading={!isGrowsurfInitialized}
          className={twMerge('w-full', isOpen ? 'hidden' : 'block')}
          variant="charcoal"
          onClick={handleButtonClick}>
          {i18n.t('checkout.confirmation.referral.modal.button')}
        </Button>
      </div>
    </div>
  );
};
