import { Boost } from 'freely-shared-types';

import { i18n } from '@i18n';
import { ROUTES, ROUTE_PATHS, getCurrentPathname, router } from '@routes/router';
import { closeModalSelector, useBoostDetailsStore, useModalStore } from '@store';
import { testProps } from '@utils';

import { Button } from '../button';
import { Text } from '../text';

const getBoostDetails = useBoostDetailsStore.getState().getBoostDetails;
export function BoostModalHeader({ boost }: { boost: Boost }) {
  const closeModal = useModalStore(closeModalSelector);

  const isShowingDetailsButton = getCurrentPathname()?.includes(ROUTES.BOOST_DETAILS);
  const boostName = boost?.name ?? '';

  const onClick = async () => {
    await getBoostDetails(boost.boostId);
    router.navigate(ROUTE_PATHS().boostDetails(boost.boostId));
    closeModal();
  };

  return (
    <div className="flex flex-row  items-center">
      <Text className="uppercase" variant="body-16/r">
        {boostName}
      </Text>
      {isShowingDetailsButton && (
        <Button
          {...testProps(`show-trip-extra-detail-button-${boostName}`)}
          variant="snow"
          size="sm"
          className="ml-2 mb-1"
          onClick={onClick}>
          {i18n.t('boostDetails.modal.actions.details')}
        </Button>
      )}
    </div>
  );
}
