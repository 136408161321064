import { useRegionStore } from 'freely-shared-stores';
import { getDurationOfDatesInDays } from 'freely-shared-utils';

import { i18n } from '@i18n';

import { Text } from '../text';

type DaysOfTravelProps = {
  startDate: string | undefined;
  endDate: string | undefined;
};

/**
 * Component to display number of travel days based on start and end dates
 */
export const DaysOfTravel = ({ startDate, endDate }: DaysOfTravelProps) => {
  const country = useRegionStore(state => state.region?.country);
  const days =
    startDate && !endDate
      ? 1
      : startDate && endDate && getDurationOfDatesInDays(startDate, endDate, country);

  return (
    <div className="px-0.7 flex flex-col items-center p-3 sm:px-4">
      <Text variant="subHeading-14/r">{i18n.t('travelDates.dateSelected.days')}</Text>
      <Text variant="subHeading-14/sb">{days ? days : '-'}</Text>
    </div>
  );
};
