import { Button, CheckboxSVGIcon, CloseSVGIcon, CoveredCard, Text } from '@components';
import { useEmcTraveller } from '@hooks';
import { i18n } from '@i18n';
import { useBoostDetailsStore, useEmcStore } from '@store';

type ConditionCardProps = {
  coveredType: 'covered' | 'notCovered';
  className?: string;
};

const titleId = {
  covered: 'emcShared.screeningResult.conditionCard.title.covered',
  notCovered: 'emcShared.screeningResult.conditionCard.title.notCovered',
} as const;

export const ConditionCard = ({ coveredType, className }: ConditionCardProps) => {
  const isCovered = coveredType === 'covered';
  const { emcCurrentTraveller, emcDeclaring } = useEmcTraveller();
  const { medicalConditions, decryptedScreeningResult } = emcCurrentTraveller ?? {};
  const setEmcDeclaring = useBoostDetailsStore(state => state.setEmcDeclaring);
  const setOpenEmcSection = useEmcStore(state => state.setOpenEmcSection);
  const filteredMedicalConditions = medicalConditions?.filter(item => item.isCovered === isCovered);
  if (!medicalConditions?.length || !filteredMedicalConditions?.length || !emcDeclaring) {
    return null;
  }

  const Icon = isCovered ? CheckboxSVGIcon : CloseSVGIcon;

  const handleEditMedicalConditions = () => {
    setEmcDeclaring({
      ...emcDeclaring,
      screeningData: decryptedScreeningResult?.[0]?.ScreeningPath?.[0]?.SystemData?.ScreeningData,
    });
    setOpenEmcSection('assessment');
  };

  return (
    <div className={className}>
      <Text className="mb-4" variant="body-16/r">
        {i18n.t(titleId[coveredType])}
      </Text>
      <CoveredCard variant={isCovered ? 'mint' : 'cherry'}>
        <div className="flex justify-between items-center">
          <div className="space-y-2">
            {filteredMedicalConditions.map(item => (
              <div key={`condition-${item}`} className="flex items-start space-x-3">
                <Icon isChecked className="w-5 h-5 flex-shrink-0" />
                <Text className="leading-tight" variant="body-16/sb">
                  {item.name}
                </Text>
              </div>
            ))}
          </div>
          <Button
            fontVariant="subHeading-14/sb"
            variant="charcoal"
            size="sm"
            className="w-[112px] flex-shrink-0"
            onClick={handleEditMedicalConditions}>
            {i18n.t('emcShared.screeningResult.conditionCard.button')}
          </Button>
        </div>
      </CoveredCard>
    </div>
  );
};
