import { Fragment } from 'react';

import {
  getCoolingOffPeriodDaysSelector,
  getRegionSelector,
  useConfigStore,
  useRegionStore,
} from 'freely-shared-stores';
import { logToSentry } from 'freely-shared-utils';

import { Assets } from '@assets';
import { Button, Text } from '@components';
import { i18n } from '@i18n';
import { useBoostDetailsStore } from '@store';
import { testProps } from '@utils';

const TAG = 'AdditionalInformation';
export const AdditionalInformation = () => {
  const region = useRegionStore(getRegionSelector);
  const coolingDaysSelector = useConfigStore(getCoolingOffPeriodDaysSelector);

  const coolOffPeriod = coolingDaysSelector(region?.state ?? '');
  const pdsLink = useBoostDetailsStore(
    state => state?.mandatoryBoostDetails?.template?.pdsLink?.value,
  );
  const additionalInformation = useBoostDetailsStore(
    state => state?.mandatoryBoostDetails?.template?.additionalInformation,
  );

  const onPdsLinkClick = () => {
    if (!pdsLink) {
      return;
    }
    try {
      window.open(pdsLink, '_blank', 'noopener,noreferrer');
    } catch (e) {
      logToSentry(e as Error, { tag: TAG });
    }
  };

  return (
    <div className="border-t pt-8 mt-4">
      <div className="flex pb-6">
        <Assets.CalendarCoolOff height={32} width={32} className="text-fuji-800 mr-2" />
        <Text variant="h4-24/sb">
          {i18n.t('myQuote.additionalInformation.coolingOff', {
            cool_off_period: coolOffPeriod,
          })}
        </Text>
      </div>
      <Text variant="body-16/r">
        {i18n.t('myQuote.additionalInformation.content', {
          cool_off_period: coolOffPeriod,
        })}
      </Text>
      <div className="border-t my-8" />
      <Text variant="h3-28/sb" className="pb-6">
        {additionalInformation?.heading}
      </Text>
      {additionalInformation?.items.map(item => (
        <Fragment key={item.title}>
          <Text variant="subTitle-20/sb" className="mb-3">
            {item.title}
          </Text>
          <Text variant="body-16/r" className="mb-8">
            {item.description}
          </Text>
        </Fragment>
      ))}
      {pdsLink && (
        <Button
          {...testProps('what-is-cover-button')}
          onClick={onPdsLinkClick}
          variant="snow"
          className="w-[50%] md:w-[30%] lg:w-[40%]">
          {i18n.t('myQuote.additionalInformation.buttonAction')}
        </Button>
      )}
    </div>
  );
};
