import { FC } from 'react';

import { getRegionSelector, useRegionStore } from 'freely-shared-stores';
import { Boost, BoostPriceType } from 'freely-shared-types';
import { isBoostEligible } from 'freely-shared-utils';

import { Price } from './price.component';
import { Unit } from './unit.component';

interface Props {
  boost: Boost;
  type: BoostPriceType;
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
}

export const BoostPrice: FC<Props> = ({ boost, className, type }) => {
  const region = useRegionStore(getRegionSelector);

  // if the pre trip cancellation is not eligible, we don't want to show the price
  const isPreTripCancellationEligible = isBoostEligible(boost, region);
  if (!isPreTripCancellationEligible) return null;

  const priceClassName = type === 'boostCard' ? 'pr-1 -mb-1' : undefined;

  return (
    <div className={className || 'flex w-full md:w-auto'}>
      <Price boost={boost} type={type} className={priceClassName} />
      <Unit boost={boost} type={type} />
    </div>
  );
};
