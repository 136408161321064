import { twMerge } from 'tailwind-merge';

import { colors } from 'freely-shared-design';

import { Assets } from '@assets';
import { Popover as PopoverComponent, Transition } from '@headlessui/react';

import { Text, TextProps } from '../text';

export interface PopoverProps {
  contentProps?: TextProps;
  titleProps?: TextProps;
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  children?: React.ReactNode;
}

export const Popover: React.FC<PopoverProps> = ({
  titleProps,
  contentProps,
  className,
  children,
}) => {
  return (
    <PopoverComponent className={twMerge('relative', className)}>
      <PopoverComponent.Button
        className={'border-transparent focus:border-transparent focus:ring-0'}>
        {children}
      </PopoverComponent.Button>
      <Transition
        className={twMerge(
          'absolute z-10 sm:bottom-[4em] sm:left-[-16em] bottom-[2.5em] left-[-7em]',
          contentProps?.className,
        )}
        enter="transition duration-100 ease-out"
        enterFrom="transform scale-95 opacity-0"
        enterTo="transform scale-100 opacity-100"
        leave="transition duration-75 ease-out"
        leaveFrom="transform scale-100 opacity-100"
        leaveTo="transform scale-95 opacity-0">
        <PopoverComponent.Panel className="w-[16em] bg-nusa-50 rounded-[0.8rem] p-5 shadow border-nusa-100 border-2">
          {({ close }) => (
            <div>
              <div className="flex justify-between items-center mb-4">
                {titleProps ? <Text {...titleProps} /> : null}
                <div className="lg:hidden">
                  <Assets.CloseCircleLight
                    cursor="pointer"
                    fill={colors.fuji[300]}
                    height={26}
                    width={26}
                    onClick={() => close()}
                  />
                </div>
              </div>
              {contentProps ? <Text {...contentProps} /> : null}
            </div>
          )}
        </PopoverComponent.Panel>
      </Transition>
    </PopoverComponent>
  );
};
