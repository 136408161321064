import { Assets } from '@assets';
import { Container, Text } from '@components';
import { useNavigateToFreely } from '@hooks';
import { i18n } from '@i18n';
import { useTripStore } from '@store';
import { testProps } from '@utils';

import { FREELY_WEBSITE_ROUTES } from '../../types/constants';

export function PaymentErrorBanner() {
  const { navigateToFreelyWebsite } = useNavigateToFreely();
  const policyStatus = useTripStore(state => state.trip?.policyStatus);
  if (policyStatus !== 'ERROR') return null;

  return (
    <div className="bg-red-500">
      <Container className="bg-red-500 flex w-full flex-row text-mono-100">
        <div className="md: flex flex-row flex-wrap items-center lg:justify-center gap-1 w-full">
          <Assets.AlertIcon fill="white" className="mr-2" />
          <Text variant="subTitle-20/sb">{i18n.t('checkout.payment.error.title')}</Text>
          <span className="hidden lg:block">-</span>
          <Text variant="subTitle-20/m">{i18n.t('checkout.payment.error.subtitle')}</Text>
          <a
            {...testProps('payment-error-navigate-back-to-freely-website-link')}
            className="cursor-pointer"
            onClick={() =>
              navigateToFreelyWebsite({
                route: FREELY_WEBSITE_ROUTES.GET_HELP,
                isRedirect: false,
              })
            }>
            <Text variant="subTitle-20/m" className="underline underline-offset-4">
              {i18n.t('checkout.payment.error.contact')}
            </Text>
          </a>
        </div>
      </Container>
    </div>
  );
}
