import { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { twMerge } from 'tailwind-merge';

import { dobFormat } from 'freely-shared-constants';
import {
  getRegionSelector,
  regionDateUtils,
  useConfigStore,
  useRegionStore,
} from 'freely-shared-stores';
import { GuestDetailsForm, ManualAddressFormInterface } from 'freely-shared-types';
import {
  dateToUTCFormat,
  logToSentry,
  primaryAgeValidation,
  utcToDateFormat,
  validate,
  validateDate,
} from 'freely-shared-utils';

import { Assets } from '@assets';
import { CoveredCard, Input, MultiConditions, Text } from '@components';
import { useEmcTraveller } from '@hooks';
import { i18n } from '@i18n';
import {
  sendEmailQuote,
  tripUserIdSelector,
  useEmcStore,
  useFeatureFlagsStore,
  useTripStore,
} from '@store';
import {
  sendAnalyticsEvent,
  shouldShowConfirmAssessmentOnNameChange,
  showEmcCoveredCard,
} from '@utils';

import { AddressInput } from './addressInput.component';
import { EmcCheckRadioGroup } from './emcCheckRadioGroup.component';
import { EmcConfirmAssessment } from './emcConfirmAssessment.component';

const TAG = 'PrimaryTravellerForm';

type Props = {
  onFormValidate?: (isValid: boolean) => void;
};

export const PrimaryTravellerForm: React.FC<Props> = ({ onFormValidate }) => {
  const primaryTravellerId = useTripStore(tripUserIdSelector);
  const {
    formState: { errors, isValid },
    control,
    watch,
    trigger,
    setValue,
    getValues,
  } = useFormContext<GuestDetailsForm & ManualAddressFormInterface>();

  useEffect(() => {
    onFormValidate?.(isValid);
  }, [isValid, onFormValidate]);

  useEffect(() => {
    if (primaryTravellerId) {
      setValue('primaryTravellerId', primaryTravellerId);
    }
  }, [primaryTravellerId, setValue]);

  const age = regionDateUtils().getAge(watch('dob') ?? '');
  const regionSpecificConfig = useConfigStore(state => state?.regionSpecificConfig);

  const region = useRegionStore(getRegionSelector);
  const [shouldCallEmailQuote, setShouldCallEmailQuote] = useState(true);
  const hasValidFirstName = !!watch('firstName') && !errors.firstName;
  const hasValidLastName = !!watch('lastName') && !errors.lastName;
  const hasValidEmail = !!watch('email') && !errors.email;
  const { getEmcTravellerById } = useEmcTraveller();
  const emcPrimaryTraveller = getEmcTravellerById(primaryTravellerId);
  const isAbandonCartEnabled = useFeatureFlagsStore(
    state => state?.featureFlags?.['abandon-cart']?.isEnabled,
  );
  const hasEmailFormValues = hasValidFirstName && hasValidLastName && hasValidEmail;
  const setNameChangedTravellers = useEmcStore(state => state.setNameChangedTravellers);
  const removeNameChangedTraveller = useEmcStore(state => state.removeNameChangedTraveller);

  const formTitle = i18n.t('travellerForm.primaryTraveller.title');

  const dateOfBirthLabel = i18n.t('travellerForm.dobInput.label');

  const sendEmail = async () => {
    if (hasEmailFormValues && shouldCallEmailQuote && isAbandonCartEnabled) {
      try {
        await sendEmailQuote(
          {
            firstName: watch('firstName') ?? '',
            lastName: watch('lastName') ?? '',
            email: watch('email') ?? '',
          },
          'abandonCart',
        );
      } catch (e) {
        logToSentry(e as Error, { tag: TAG });
      } finally {
        setShouldCallEmailQuote(false);
      }
    }
  };

  const validateField = async (fieldName: keyof GuestDetailsForm) => {
    const isValid = await trigger(fieldName);
    if (!isValid) {
      setShouldCallEmailQuote(true);
    }
  };

  return (
    <>
      <div className="mb-4 flex gap-1">
        <Text variant="h4-24/sb">{formTitle}</Text>
        {age
          ? !errors.dob && (
              <Text className="text-fuji-300" variant="h4-24/r">
                {i18n.t('travellerForm.ageLabel', { age })}
              </Text>
            )
          : null}
      </div>
      <div className={'mb-4 flex flex-col gap-2 lg:flex-row'}>
        <div className="flex-1">
          <Controller
            name="firstName"
            control={control}
            rules={{ validate: v => validate('firstName', v ?? '') ?? undefined }}
            render={({ field, fieldState: { error } }) => (
              <Input
                isSuccess={hasValidFirstName}
                labelProps={{ children: i18n.t('travellerForm.firstNameInput.label') }}
                placeholder={i18n.t('travellerForm.firstNameInput.placeholder')}
                {...field}
                onBlur={async e => {
                  field.onBlur();
                  setValue('firstName', e.target.value.trim());
                  if (
                    shouldShowConfirmAssessmentOnNameChange(
                      { firstName: e.target.value.trim(), lastName: getValues('lastName') ?? '' },
                      emcPrimaryTraveller,
                    )
                  ) {
                    setNameChangedTravellers(primaryTravellerId);
                  } else {
                    removeNameChangedTraveller(primaryTravellerId);
                  }
                  await validateField('firstName');
                  if (watch('firstName') && !error) {
                    formAnalyticEvent('firstName');
                  }
                  await sendEmail();
                }}
                onChange={async e => {
                  field.onChange(e);
                  setValue('firstName', e.target.value);
                  await validateField('firstName');
                }}
                autoComplete="given-name"
                type="text"
                hasError={!!error}
                errorMessage={error?.message}
              />
            )}
          />
        </div>
        <div className="flex-1">
          <Controller
            name="lastName"
            control={control}
            rules={{ validate: v => validate('lastName', v ?? '') ?? undefined }}
            render={({ field, fieldState: { error } }) => (
              <Input
                isSuccess={hasValidLastName}
                labelProps={{ children: i18n.t('travellerForm.lastNameInput.label') }}
                placeholder={i18n.t('travellerForm.lastNameInput.placeholder')}
                {...field}
                autoComplete="family-name"
                onBlur={async e => {
                  field.onBlur();
                  setValue('lastName', e.target.value.trim());

                  await validateField('lastName');
                  if (
                    shouldShowConfirmAssessmentOnNameChange(
                      { firstName: getValues('firstName') ?? '', lastName: e.target.value.trim() },
                      emcPrimaryTraveller,
                    )
                  ) {
                    setNameChangedTravellers(primaryTravellerId);
                  } else {
                    removeNameChangedTraveller(primaryTravellerId);
                  }

                  if (watch('lastName') && !error) {
                    formAnalyticEvent('lastName');
                  }

                  await sendEmail();
                }}
                onChange={async e => {
                  field.onChange(e);
                  setValue('lastName', e.target.value.trim());
                  await validateField('lastName');
                }}
                type="text"
                hasError={!!error}
                errorMessage={error?.message}
              />
            )}
          />
        </div>
      </div>
      <Controller
        control={control}
        name="email"
        rules={{ validate: v => validate('email', v ?? '') ?? undefined }}
        render={({ field, fieldState: { error } }) => (
          <Input
            inputMode="email"
            containerProps={{ className: 'mb-4' }}
            isSuccess={hasValidEmail}
            labelProps={{ children: i18n.t('travellerForm.emailInput.label') }}
            placeholder={i18n.t('travellerForm.emailInput.placeholder')}
            {...field}
            onChange={async e => {
              field.onChange(e);
              setValue('email', e.target.value.trim());
              await validateField('email');
            }}
            onBlur={async () => {
              field.onBlur();
              await validateField('email');
              if (watch('email') && !error) {
                formAnalyticEvent('email');
              }
              await sendEmail();
            }}
            type="email"
            autoComplete="email"
            hasError={!!error}
            errorMessage={error?.message}
          />
        )}
      />
      <div className="flex-1 mb-4">
        <Controller
          control={control}
          name="dob"
          rules={{
            validate: {
              validDate: v => validateDate(v ?? ''),
              validateAge: v =>
                primaryAgeValidation(v ?? '', {
                  min: regionSpecificConfig?.RULES.PRIMARY_TRAVELLER_AGE.min,
                  max: regionSpecificConfig?.RULES.PRIMARY_TRAVELLER_AGE.max,
                  country: region?.country,
                }),
            },
          }}
          render={({ field, fieldState: { error } }) => (
            <PatternFormat
              format={dobFormat}
              mask="_"
              customInput={Input}
              inputMode="numeric"
              hasError={!!error}
              errorMessage={error?.message}
              isSuccess={!error && !!watch('dob')}
              onChange={e => {
                field.onChange(dateToUTCFormat(e.target.value, region?.country));
              }}
              onBlur={() => {
                field.onBlur();
                if (watch('dob') && !error) {
                  formAnalyticEvent('dob');
                }
              }}
              value={utcToDateFormat(field.value ?? '', region?.country)}
              labelProps={{ children: dateOfBirthLabel }}
              placeholder={i18n.t('travellerForm.dobInput.placeholder')}
              svgIcon={
                <Assets.Calendar className={twMerge('text-gray-400', !!error && '!text-red-300')} />
              }
            />
          )}
        />
      </div>
      {region && <AddressInput region={region} />}
      {showEmcCoveredCard(emcPrimaryTraveller) ? (
        <CoveredCard variant={emcPrimaryTraveller?.isAdded ? 'mint' : 'cherry'} className="my-4">
          <MultiConditions
            travellerId={primaryTravellerId}
            age={age}
            firstName={getValues('firstName') ?? ''}
            lastName={getValues('lastName') ?? ''}
          />
        </CoveredCard>
      ) : (
        <EmcCheckRadioGroup
          firstName={getValues('firstName')}
          lastName={getValues('lastName')}
          sortKey={primaryTravellerId}
          dob={getValues('dob')}
        />
      )}
      <EmcConfirmAssessment
        travellerId={primaryTravellerId}
        age={age}
        firstName={getValues('firstName') ?? ''}
        lastName={getValues('lastName') ?? ''}
      />
    </>
  );
};

/**
 * format for analytic events for this form
 * @param fieldName - string
 */
export const formAnalyticEvent = (fieldName: string) => {
  sendAnalyticsEvent('Traveller details field validated', {
    fieldName,
  });
};
