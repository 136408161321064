import React from 'react';

import { BoostCoverTable } from 'freely-shared-types';

import { Text } from '../text';

interface Props {
  item: BoostCoverTable;
}

export const TableItem: React.FC<Props> = ({ item }) => {
  return (
    <div>
      <Text variant="body-16/sb" className="pb-3.5">
        {item.columnNames.title}
      </Text>
      <table className="w-full">
        <tbody>
          {item.items.map((rowItem, index) => {
            return (
              <tr className="border-y-2 border-gray-200" key={index}>
                <td className="py-4 px-0">
                  <Text variant="body-16/r"> {rowItem.title}</Text>
                </td>
                <td className="py-4 text-right">
                  <Text variant="body-16/sb"> {rowItem.value}</Text>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
