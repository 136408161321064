import { Boost, BoostPriceType, Trip } from 'freely-shared-types';
import {
  convertCentsToDollars,
  isBoostExtraCancellation,
  isBoostSpecifiedItems,
  isTripFreeOfCharge,
} from 'freely-shared-utils';

import { getTripSelector, useTripStore } from '@store';

import { Text } from '../text';

interface PriceProps {
  boost: Boost;
  type?: BoostPriceType;
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
}

export const Price = ({ boost, type, className }: PriceProps) => {
  const trip = useTripStore(getTripSelector);
  const isFreeOfCharge = isTripFreeOfCharge(trip);
  const isBoostCard = type === 'boostCard';

  const boostPrice = calculateBoostValue({
    boost,
    trip,
    type,
  });

  const boostPriceString =
    typeof boostPrice === 'string' ? boostPrice : convertCentsToDollars(boostPrice);

  const variant = isBoostCard ? 'body-16/sb' : 'h4-24/sb';

  if (boostPrice != null || isFreeOfCharge) {
    return (
      <Text variant={variant} className={className}>
        {boostPriceString}
      </Text>
    );
  }

  return null;
};

interface CalculateBoostValue {
  boost: Boost;
  trip: Trip;
  type?: BoostPriceType;
}

export const calculateBoostValue = ({ boost, trip, type }: CalculateBoostValue) => {
  const isSpecifiedItems = isBoostSpecifiedItems(boost);
  const isExtraCancellation = isBoostExtraCancellation(boost);
  const isFreeOfCharge = isTripFreeOfCharge(trip);
  const isAdded = boost?.toUpdate?.isAdded ?? boost?.isAdded;
  // by type
  const isBoostModal = type === 'boostModal';
  const isBoostDetails = type === 'boostDetails';
  const isBoostCard = type === 'boostCard';

  const boostPrice = boost?.toUpdate?.adjustedPrice || parseInt(boost?.price, 10);
  const payPerDayRate =
    boost?.toUpdate?.boostProperty?.payPerDayRate || boost?.boostProperty?.payPerDayRate || 0;

  /**
   * This is for showing $0 if the boost is "Specified Item" or "Extra Cancellation" and there is no price selected.
   * On the extra cancellation modal we still want to display the price even if the boost is not added.
   */

  if (isBoostModal && isExtraCancellation && isFreeOfCharge) {
    return 0;
  }

  if (isBoostDetails) {
    if (isSpecifiedItems && !isAdded) {
      return 'No';
    }

    if (isExtraCancellation && !isAdded) {
      return 0;
    }
  }

  if (isBoostCard) {
    if ((isExtraCancellation || isSpecifiedItems) && !isAdded) {
      return null;
    }

    if (isFreeOfCharge) {
      return 0;
    }

    if (payPerDayRate) {
      return payPerDayRate;
    }
  }

  /**
   * Handle returning total boost price OR pay per day rate
   */

  if (payPerDayRate > 0 && boostPrice === 0) {
    return payPerDayRate;
  }

  return boostPrice;
};
