import { FC, useMemo } from 'react';

import { colors } from 'freely-shared-design';

export interface CloseSVGIconProps {
  variant?: {
    theme?: 'cherry';
  };
  className?: string;
}

// Note: This is a simplified version of the CloseSVGIcon component. We have 2 variant styling of the close svg in cherry one with a black cross and the other with the white cross (NotCovered Asset)
export const CloseSVGIcon: FC<CloseSVGIconProps> = ({ variant, className }) => {
  const theme = useMemo(() => getThemeVariant(variant?.theme ?? 'cherry'), [variant?.theme]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      className={className}>
      <rect width="20" height="20" rx="10" fill={theme.fillColor} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.000365736 9.93196C0.044587 4.46659 4.51161 0 10.0002 0C15.5782 0.0439949 20.0225 4.5548 19.9999 10.0646C19.9557 15.53 15.4887 19.9966 10.0001 19.9966C7.26666 19.975 4.75547 18.9308 2.8892 17.0196C1.00035 15.1309 -0.0222027 12.5983 0.000365736 9.93196L14.2223 14.2205C14.4888 13.9541 14.4888 13.5314 14.2223 13.2649L10.9558 9.99884L14.2222 6.73277C14.4887 6.46632 14.4887 6.04361 14.2222 5.77715C13.9557 5.51069 13.533 5.51069 13.2665 5.77715L10.0001 9.04322L6.73374 5.75449C6.46726 5.48803 6.04451 5.48803 5.77803 5.75449C5.51154 6.02095 5.51154 6.44365 5.77803 6.71011L9.0444 9.99884L5.77803 13.2649C5.51154 13.5314 5.51154 13.9541 5.77803 14.2205C5.91169 14.3542 6.11135 14.4202 6.26673 14.4202C6.42299 14.4202 6.60006 14.3542 6.73374 14.2205L10.0001 10.9545L13.2883 14.2205C13.422 14.3533 13.5773 14.4202 13.7553 14.4202C13.9116 14.4202 14.0886 14.3542 14.2223 14.2205L0.000365736 9.93196Z"
        fill={theme.pathColor}
      />
    </svg>
  );
};

const getThemeVariant = (theme: string) => {
  switch (theme) {
    case 'cherry':
      return {
        fillColor: colors.fuji[800],
        pathColor: colors.red[300],
      };
    default:
      return {};
  }
};
