import { RefObject, useLayoutEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { TravellerMedicalConditions } from 'freely-shared-types';

import { Assets } from '@assets';
import { Text } from '@components';

interface TruncatedElementProps {
  ref: RefObject<HTMLElement>;
}

const useTruncatedElement = ({ ref }: TruncatedElementProps) => {
  const [isTruncated, setIsTruncated] = useState(false);
  const [showFullContent, setShowFullContent] = useState(false);
  const { offsetHeight, scrollHeight } = ref.current || {};

  useLayoutEffect(() => {
    const checkIfTruncated = () => {
      if (offsetHeight && scrollHeight && offsetHeight < scrollHeight) {
        setIsTruncated(true);
      } else {
        setIsTruncated(false);
      }
    };
    checkIfTruncated();

    const handleResize = () => {
      setShowFullContent(false); // Optionally reset to truncated view on resize
      checkIfTruncated();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [ref, scrollHeight, offsetHeight]);

  return {
    isTruncated,
    showFullContent,
    setShowFullContent,
  };
};

type SeeMoreProps = {
  medicalConditionList: TravellerMedicalConditions[];
};

export const SeeMore = ({ medicalConditionList }: SeeMoreProps) => {
  const ref = useRef(null);
  const { isTruncated, showFullContent, setShowFullContent } = useTruncatedElement({
    ref,
  });

  const list = medicalConditionList.map(({ name }) => name);

  return (
    <div>
      <Text ref={ref} variant="subHeading-14/r" className={`${!showFullContent && 'line-clamp-1'}`}>
        {list.join(', ')}
      </Text>

      {/* If the list is within one line or less it will not show the See More button */}
      {isTruncated && (
        <button
          className="underline cursor-pointer flex items-center"
          onClick={() => setShowFullContent(!showFullContent)}>
          <Text variant="subHeading-14/r">{showFullContent ? 'See less' : 'See more'}</Text>
          <Assets.ChevronDown
            fill="black"
            width="16px"
            height="16px"
            className={twMerge(
              'ml-1 transform transition-transform cursor-pointer',
              showFullContent ? 'rotate-180' : 'rotate-0',
            )}
          />
        </button>
      )}
    </div>
  );
};
