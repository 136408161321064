import { useEffect } from 'react';

import { Button, Container, CovidConsentFooter, Notification, Text } from '@components';
import { i18n } from '@i18n';
import { ROUTE_PATHS, router } from '@routes/router';
import { closeModalSelector, useGlobalStore, useModalStore } from '@store';
import { sendAnalyticsEvent, sendAnalyticsPage, testProps } from '@utils';

import { ListItems } from './listItems.component';

export type CovidConsentProps = {
  isModal?: boolean;
};
export const CovidConsent = ({ isModal = false }: CovidConsentProps) => {
  const setIsCovidConsentAgreed = useGlobalStore(state => state.setIsCovidConsentAgreed);
  const closeModal = useModalStore(closeModalSelector);

  useEffect(() => {
    sendAnalyticsPage('DFAT Page Viewed');
  }, []);

  const onAgreePress = async () => {
    sendAnalyticsEvent('Covid Consent Agree Clicked');
    setIsCovidConsentAgreed(true);
    router.navigate(ROUTE_PATHS().myQuote);
    if (isModal) {
      closeModal();
    }
  };

  const onBackPress = () => {
    sendAnalyticsEvent('Covid Consent Back Clicked');
    router.navigate(-1);
    if (isModal) {
      closeModal();
    }
  };

  return (
    <Container
      className="md:px-10"
      titleText={i18n.t('covidConsent.title')}
      titleVariant="h2-36/sb"
      subtitleVariant="h4-24/m"
      subtitleText={i18n.t('covidConsent.subtitle')}
      subtitleClassName="lg:w-[80%] xl:w-[80%] m-auto">
      <div className="grid gap-8 pt-8 lg:grid-flow-col">
        <div>
          <Text variant="subTitle-20/sb" className="mb-5">
            {i18n.t('covidConsent.content.title.covered')}
          </Text>
          <ListItems type="covered" />
        </div>
        <div>
          <Text variant="subTitle-20/sb" className="mb-5">
            {i18n.t('covidConsent.content.title.notCovered')}
          </Text>
          <ListItems type="notCovered" />
        </div>
      </div>
      <Notification
        variant="info"
        headingProps={{
          children: i18n.t('covidConsent.notificationInfo'),
          variant: 'subHeading-14/r',
        }}
      />
      <div className="w-full mt-6 border-t border-cabo-200">
        <CovidConsentFooter />
      </div>
      <div className="flex items-center w-full gap-4 py-6">
        <Button
          className="w-full"
          {...testProps('covid-consent-back-button')}
          variant="snow"
          onClick={onBackPress}>
          {i18n.t('covidConsent.buttonActions.back')}
        </Button>
        <Button
          className="w-full"
          {...testProps('covid-consent-agree-and-continue-button')}
          onClick={onAgreePress}>
          {i18n.t('covidConsent.buttonActions.agreeAndContinue')}
        </Button>
      </div>
    </Container>
  );
};
