import React from 'react';
import { useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { shallow } from 'zustand/shallow';

import { colors } from 'freely-shared-design';
import { getRegionSelector, useRegionStore } from 'freely-shared-stores';
import { getBoostModalType, isBoostAdded, isBoostEligible } from 'freely-shared-utils';

import { Assets } from '@assets';
import { useBoostSelectionModal } from '@hooks';
import { i18n } from '@i18n';
import { getSelectedBoostById, useBoostDetailsStore, useTripStore } from '@store';
import { sendAnalyticsEvent } from '@utils';

import { BoostPrice } from '../boostPrice';
import { Button } from '../button';
import { Text } from '../text';
import { getBoostPrimaryButtonAttributes } from './boostHeader.utils';

export const BoostHeader: React.FC = () => {
  const params = useParams();
  const boostId = params?.id ?? '';
  const addBoost = useTripStore(state => state.addBoost);
  const removeBoost = useTripStore(state => state.removeBoost);
  const [title, boostCategoryCaption, description] = useBoostDetailsStore(state => {
    const mandatoryBoostId = state?.mandatoryBoostDetails?.boostId;
    const details = mandatoryBoostId === boostId ? state.mandatoryBoostDetails : state.boostDetails;
    return [
      details?.template?.title,
      details?.template?.categoryCaption,
      details?.template?.description,
    ];
  }, shallow);
  const selectedBoost = useTripStore(getSelectedBoostById(boostId));
  const region = useRegionStore(getRegionSelector);
  const isBoostAddedToTrip = isBoostAdded(selectedBoost);
  const isMandatoryBoostAdded = !!selectedBoost?.isMandatory && isBoostAddedToTrip;

  const openBoostSelectionModal = useBoostSelectionModal(boostId);
  const boostModalType = getBoostModalType(selectedBoost);
  const isDisplayingPrimaryButtonRemoveIcon =
    boostModalType && boostModalType !== 'addSpecifiedItems' ? false : isBoostAddedToTrip;
  const isBoostExtraCancellationAndAdded =
    boostModalType === 'extraCancellation' && isBoostAddedToTrip;
  const isPayPerDayAndAdded = boostModalType === 'payPerDayCalendar' && isBoostAddedToTrip;
  const isSecondaryButtonDisplayed =
    (isBoostExtraCancellationAndAdded || isPayPerDayAndAdded) && !isMandatoryBoostAdded;
  const isPreTripCancellationEligible = isBoostEligible(selectedBoost, region);
  const [primaryButtonText, primaryButtonVariant] = getBoostPrimaryButtonAttributes(
    selectedBoost,
    isPreTripCancellationEligible,
  );

  //All boosts that only display one button at the header will have the Remove Button tinted
  // Boosts with secondary remove button will display the first button as always charcoal
  const isPrimaryRemoveButtonTinted =
    !isSecondaryButtonDisplayed && isBoostAddedToTrip && !isMandatoryBoostAdded;

  const onPrimaryButtonClick = async () => {
    if (isMandatoryBoostAdded) {
      return;
    }

    /**
     * Opens boost selection modal, if the boost is not added
     */
    if (boostModalType) {
      // add specified items will have the remove all option if the boost is added
      if (boostModalType === 'addSpecifiedItems' && isBoostAddedToTrip) {
        sendAnalyticsEvent('All Specified Items Removed');
        return await removeBoost({ boostId });
      }
      return openBoostSelectionModal();
    }

    /**
     * Boost is added to trip
     * So we remove
     */
    if (isBoostAddedToTrip) {
      return await removeBoost({ boostId });
    }

    /**
     * All other scenarios will lead the action to add the boost
     */

    return await addBoost({ boostId, updatePayload: {} });
  };

  return (
    <div
      className={twMerge(
        'bg-nusa-200 w-full pt-8 pb-32 md:pt-14',
        !isPreTripCancellationEligible && '!bg-fuji-50',
      )}>
      <div className="entry-content w-full space-y-2 lg:space-y-3">
        <Text variant="body-16/r" className="text-fuji-800 uppercase">
          {boostCategoryCaption}
        </Text>
        <Text
          variant={{
            lg: 'h2-36/sb',
            md: 'h2-36/sb',
            sm: 'h2-36/sb',
          }}
          className="text-fuji-800 h2-36/sb">
          {title}
        </Text>
        <Text variant="h4-24/r" className="text-fuji-800 w-full pb-2 lg:w-9/12">
          {description}
        </Text>
        <div className="flex flex-wrap items-center gap-4">
          {selectedBoost && <BoostPrice boost={selectedBoost} type="boostDetails" />}
          <Button
            size="md"
            variant={primaryButtonVariant}
            IconLeft={
              isMandatoryBoostAdded
                ? Assets.Mandatory
                : isDisplayingPrimaryButtonRemoveIcon
                ? Assets.CloseCircleLight
                : undefined
            }
            IconLeftProps={{ fill: '#131E29' }}
            onClick={onPrimaryButtonClick}
            className={twMerge(
              isPreTripCancellationEligible && 'h-11 pl-4 pr-4 md:pl-14 md:pr-14',
              isPrimaryRemoveButtonTinted && isPreTripCancellationEligible && 'bg-fuji-800/20',
              isMandatoryBoostAdded && isPreTripCancellationEligible && 'pointer-events-none',
            )}
            disabled={!isPreTripCancellationEligible}>
            {primaryButtonText}
          </Button>
          {isSecondaryButtonDisplayed && (
            <Button
              size="md"
              variant="mint"
              IconLeft={Assets.CloseCircleLight}
              IconLeftProps={{ fill: colors.fuji[800] }}
              onClick={async () => {
                await removeBoost({ boostId });
              }}
              className="bg-fuji-800/20 h-11 pl-7 md:pl-14 md:pr-14">
              {i18n.t('boostDetails.boostHeader.secondaryButton.remove')}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
