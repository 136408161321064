import {
  isAURegionSelector,
  isUSRegionSelector,
  useConfigStore,
  useRegionStore,
} from 'freely-shared-stores';
import { getDefaultPDSLink } from 'freely-shared-utils';

import { Assets } from '@assets';
import { EmailQuoteButton } from '@components';
import { useNavigateToFreely } from '@hooks';
import { i18n } from '@i18n';
import { ROUTE_PATHS, getCurrentPathname } from '@routes/router';
import { testProps } from '@utils';

import { version } from '../../../package.json';
import { Text } from '../text';
import { SupportEnquires } from './supportEnquiries.component';

export const Footer = ({ isNotFound = false }: { isNotFound?: boolean }) => {
  const isUS = useRegionStore(isUSRegionSelector);
  const isAU = useRegionStore(isAURegionSelector);

  const config = useConfigStore(state => state.config);

  const pdsLink =
    useConfigStore(state => state?.regionSpecificConfig?.PDS.CURRENT) ?? getDefaultPDSLink();
  const privacyLink = useConfigStore(state => state?.regionSpecificConfig?.PRIVACY_STATEMENT);
  const targetMarketLink = useConfigStore(state => state?.regionSpecificConfig?.TARGET_MARKET); // AU only
  const termsOfUseLink = useConfigStore(state => state?.regionSpecificConfig?.APP_TERMS);
  const fraudLink = useConfigStore(state => state?.regionSpecificConfig?.FRAUD_WARNING); // AU only

  const currentPathname = getCurrentPathname();
  const { openRedirectToFreelyModal, navigateToFreelyWebsite } = useNavigateToFreely();
  const { confirmation } = ROUTE_PATHS();
  const isConfirmationView = currentPathname === confirmation;
  const onFreelyIconClick = () =>
    isNotFound ? navigateToFreelyWebsite() : openRedirectToFreelyModal();

  return (
    <>
      {isConfirmationView && <SupportEnquires />}
      <footer className="mt-auto w-full relative">
        <Text variant="footnote-12/r" className="absolute bottom-0 right-2 text-cabo-200">
          v{version}
        </Text>
        <div className="bg-fuji-800">
          <div className="entry-content bg-fuji-800 flex flex-row justify-between">
            <button onClick={onFreelyIconClick} className="lg:[&>svg]:h-12 lg:[&>svg]:w-24">
              <Assets.FreelyPrime fill={'white'} />
            </button>
            <div className="flex flex-row items-center">
              <Text variant="body-16/r" className="invisible mr-5 text-mono-100 md:visible">
                {i18n.t('footer.planetSlogan')}
              </Text>
              <Assets.OnePercentPlanet fill={'white'} height={100} width={100} />
            </div>
          </div>
        </div>
        <div className="bg-fuji-800 w-full py-10 text-center">
          <div className="entry-content">
            {isAU && (
              <div className="mx-auto lg:w-[770px] md:w-[520px] w-auto flex-shrink-0 p-3">
                <Text
                  variant={{
                    sm: 'body-16/sb',
                    md: 'body-16/sb',
                    lg: 'subTitle-20/sb',
                  }}
                  className="text-mono-100">
                  {i18n.t('footer.downloadAppHeading')}
                </Text>

                <div className="mx-auto w-[288px] flex-shrink-0 p-3 mb-7">
                  <div className="flex w-full justify-between px-4">
                    <a href={config?.APP_STORE_LINK} rel="noreferrer" className="p-1">
                      <img src={Assets.AppStoreBadge} alt="app store badge" className="h-[39px]" />
                    </a>
                    <a href={config?.PLAY_STORE_LINK} rel="noreferrer" className="p-1">
                      <img
                        src={Assets.GooglePlayBadge}
                        alt="google play button"
                        className="h-[39px]"
                      />
                    </a>
                  </div>
                </div>
              </div>
            )}
            <Text variant="footnote-12/r" className="text-mono-100">
              <a
                href={pdsLink}
                target="_blank"
                rel="noreferrer"
                {...testProps('product-Disclosure-Statement')}>
                {i18n.t('footer.menuItems.item1')}
              </a>
              <a
                href={isUS ? privacyLink : targetMarketLink}
                target="_blank"
                rel="noreferrer"
                {...testProps('target-Market-Determination')}>
                {i18n.t('footer.menuItems.item2')}
              </a>
              <a
                href={isUS ? termsOfUseLink : privacyLink}
                target="_blank"
                rel="noreferrer"
                {...testProps('privacy-Statement')}>
                {i18n.t('footer.menuItems.item3')}
              </a>
              <a
                href={isUS ? fraudLink : termsOfUseLink}
                target="_blank"
                rel="noreferrer "
                {...testProps('Terms-of-Use')}>
                {i18n.t('footer.menuItems.item4')}
              </a>
              <EmailQuoteButton type="link" />
            </Text>
            <br />
            <Text variant="footnote-12/r" className="text-cabo-200">
              {i18n.t('footer.copyright')}
            </Text>
            <br />
            <Text withMarkDown variant="footnote-12/r" className="text-cabo-200">
              {i18n.t('footer.terms')}
            </Text>
          </div>
        </div>
      </footer>
    </>
  );
};
