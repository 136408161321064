import React from 'react';
import { twMerge } from 'tailwind-merge';

import { RegionResidencyLabel } from '../regionResidencyLabel';
import { Text, TextProps } from '../text';

export interface ContainerProps {
  titleText?: string;
  subtitleText?: string;
  titleClassName?: React.HTMLAttributes<HTMLHeadingElement>['className'];
  subtitleClassName?: React.HTMLAttributes<HTMLHeadingElement>['className'];
  className?: React.HTMLAttributes<HTMLDivElement>['className'];
  children?: React.ReactNode;
  titleVariant?: TextProps['variant'];
  subtitleVariant?: TextProps['variant'];
  hasRegionResidencyStatus?: boolean;
}

export const Container: React.FC<ContainerProps> = ({
  titleText,
  subtitleText,
  className,
  titleClassName,
  subtitleClassName,
  titleVariant,
  children,
  hasRegionResidencyStatus,
  subtitleVariant,
}) => {
  return (
    <div className={twMerge('entry-content py-4 md:py-6', className)}>
      {hasRegionResidencyStatus && <RegionResidencyLabel />}
      {titleText && (
        <Text
          variant={titleVariant ?? { sm: 'h3-28/freely', lg: 'h2-36/freely' }}
          className={twMerge('pb-5 pt-8 text-center', titleClassName)}>
          {titleText}
        </Text>
      )}
      {subtitleText && (
        <Text
          variant={subtitleVariant ?? 'body-16/m'}
          className={twMerge('pb-4 text-center', subtitleClassName)}>
          {subtitleText}
        </Text>
      )}
      {children}
    </div>
  );
};
