import { twMerge } from 'tailwind-merge';

import { EmcAccordionContentItem, EmcInclusionItem, EmcTextItem } from 'freely-shared-types';

import { Text } from '@components';

export type EmcAccordionContentProps = {
  readonly content: EmcAccordionContentItem;
};

export function EmcAccordionContent({ content }: EmcAccordionContentProps) {
  if (!content.length) {
    return null;
  }

  const listItems = content.map(item => {
    switch (item.type) {
      case 'text':
      case 'bold-text':
      case 'bullet-list':
      case 'number-list':
        return <EmcAccordionContentText {...item} key={item.text} />;
      case 'table':
        return <EmcInclusionTable {...item} key={item.name} />;
      default:
        return null;
    }
  });

  return <div>{listItems}</div>;
}

export function EmcInclusionTable({ items, name }: EmcInclusionItem) {
  const bottomRowText = items?.map((item, index) => {
    return (
      <EmcAccordionContentText {...item} key={`${item.text}-${index}}`} className="pl-2 pt-2" />
    );
  });

  return (
    <div className="mb-2 lg:grid lg:grid-cols-2">
      <div className="bg-mono-100 p-4 mb-1 lg:mr-1 rounded">
        <Text withMarkDown variant="body-16/sb">
          {name}
        </Text>
      </div>
      <div className="bg-mono-100 p-2 mb-1 rounded">{bottomRowText}</div>
    </div>
  );
}

export function EmcAccordionContentText({
  type,
  text,
  listNumber,
  isMarkdown,
  className,
}: EmcTextItem) {
  if (!text) {
    return null;
  }

  let prefix = '';
  if (type === 'number-list') {
    prefix = `${listNumber}.  `;
  }

  if (type === 'bullet-list') {
    prefix = `${'\u2022'}  `;
  }

  const variant = type === 'bold-text' ? 'body-16/sb' : 'body-16/r';
  const prefixText = prefix ? (
    <Text variant="body-16/r" className="mr-2">
      {prefix}
    </Text>
  ) : null;
  return (
    <div className={twMerge('flex flex-row pb-1', className)}>
      {prefixText}
      <Text withMarkDown={isMarkdown} variant={variant}>
        {text}
      </Text>
    </div>
  );
}
