import { FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';

import { PAYMENT_TYPE } from 'freely-shared-types';

import { Assets } from '@assets';
import { CardTypeIcon } from '@components';

const cardOptions = [
  { value: PAYMENT_TYPE.VISA, label: 'Visa' },
  { value: PAYMENT_TYPE.MASTER_CARD, label: 'Mastercard' },
  { value: PAYMENT_TYPE.DISCOVER, label: 'Discover' },
  { value: PAYMENT_TYPE.AMEX, label: 'American Express' },
];

interface CardTypeDropdownProps {
  value: PAYMENT_TYPE | undefined;
  showDropdown: boolean;
  onChange: (selected: PAYMENT_TYPE | undefined) => void;
}

export const CardTypeDropdown: FC<CardTypeDropdownProps> = ({ value, showDropdown, onChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleSelect = (selectedValue: PAYMENT_TYPE) => {
    onChange(selectedValue);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>, optionValue: PAYMENT_TYPE) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      handleSelect(optionValue);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen]);

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        type="button"
        onClick={toggleDropdown}
        className={twMerge(
          'w-full flex items-center border border-nevada-300 rounded-lg p-2 cursor-none',
          !value && showDropdown && 'cursor-pointer',
        )}>
        <div className="flex items-center w-full justify-between">
          <div className="flex items-center">
            <CardTypeIcon cardType={value} assetWidth={30} assetHeight={20} />
            {!value && showDropdown && (
              <div className="flex items-center gap-2">
                <Assets.CreditCardError width={20} height={20} className="ml-2" />
                <Assets.ArrowUp
                  height={10}
                  width={10}
                  className={`transform ${isOpen ? 'rotate-180' : ''} ml-auto`}
                />
              </div>
            )}
          </div>
        </div>
      </button>

      {isOpen && (
        <>
          {/* Mobile Backdrop */}
          <div
            aria-hidden="true"
            className={twMerge(`fixed inset-0 bg-fuji-800 opacity-50 z-20 sm:hidden`)}
            onClick={() => setIsOpen(false)}></div>

          {/* Dropdown */}
          <div
            className={twMerge(
              `fixed bottom-0 left-0 w-full sm:absolute sm:inset-auto sm:right-0 sm:mt-1 sm:max-w-[355px] sm:min-w-[355px] bg-mono-100 border border-nevada-300 rounded-lg shadow-lg z-30`,
            )}>
            <div className="sm:hidden p-4 flex justify-between items-center border-b">
              <span className="text-lg font-semibold">Select Card Type</span>
              <button
                type="button"
                onClick={() => {
                  setIsOpen(false);
                }}>
                <Assets.Close height={20} fill={'black'} />
              </button>
            </div>
            {/* If value exists hide the dropdown from being selected and open */}
            <div className={twMerge('max-h-60 overflow-y-auto p-2', value && 'hidden')}>
              {cardOptions.map(option => (
                <button
                  key={option.value}
                  className="w-full text-left py-2 px-6 sm:py-[6px] sm:px-6 cursor-pointer hover:bg-nusa-50 flex items-center"
                  onClick={() => handleSelect(option.value)}
                  onKeyDown={event => handleKeyDown(event, option.value)}
                  tabIndex={0}>
                  <div className="flex items-center gap-2">
                    <CardTypeIcon cardType={option.value} assetHeight={30} assetWidth={30} />
                    <span className="ml-2">{option.label}</span>
                  </div>
                </button>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};
