import { isUSRegionSelector, useRegionStore } from 'freely-shared-stores';

import { i18n } from '@i18n';
import { redirectToMyQuote } from '@routes/router';
import { openModalSelector, useCheckoutStore, useModalStore } from '@store';

export const usePriceChangeModal = () => {
  const openModal = useModalStore(openModalSelector);
  const isUS = useRegionStore(isUSRegionSelector);
  const setGoogleCreditCardDetails = useCheckoutStore(state => state?.setGoogleCreditCardDetails);
  const setPaymentSectionType = useCheckoutStore(state => state?.setPaymentSectionType);

  // For the US region, we need to reset google credit details and revert payment section to credit  card
  const handleClearUSCreditDetails = () => {
    if (isUS) {
      setGoogleCreditCardDetails({
        cardNumber: '',
        cardType: undefined,
        paymentToken: '',
      });
      setPaymentSectionType('card');
    }
  };

  const openPriceChangeModal = ({ onNext }: { onNext: () => void | Promise<void> }) =>
    openModal('Modal', {
      shouldCloseOnOverlayClick: false,
      titleText: i18n.t('global.priceChangeModal.title'),
      titleClassName: 'text-left',
      bodyTextProps: {
        children: i18n.t('global.priceChangeModal.content'),
        className: 'mt-4',
        variant: 'body-16/m',
      },
      actions: [
        {
          children: i18n.t('global.actions.continue'),
          variant: 'mint',
          onClick: async () => {
            handleClearUSCreditDetails();
            redirectToMyQuote();
            onNext();
          },
        },
      ],
      size: 'md',
    });

  return { openPriceChangeModal };
};
