import { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

import { useConfigStore } from 'freely-shared-stores';
import { getDefaultPDSLink } from 'freely-shared-utils';

import { AccordionItem, Button, EmcAccordionContent, EmcNeedHelp, Text } from '@components';
import { i18n } from '@i18n';
import { useBoostDetailsStore, useEmcStore } from '@store';
import { sendAnalyticsEvent } from '@utils';

export const EmcInformation = () => {
  const setOpenEmcSection = useEmcStore(state => state.setOpenEmcSection);

  // BR: If the user has clicked on the infoIcon, the flag will be true and we don't want to show the continue button
  const isInfoIcon = useEmcStore(state => state.isInfoIcon);
  const emcDeclaring = useBoostDetailsStore(state => state.emcDeclaring);
  const listItems = i18n.t('emcShared.information.accordionItems', {
    returnObjects: true,
    PDS_LINK: useConfigStore.getState()?.regionSpecificConfig?.PDS?.CURRENT ?? getDefaultPDSLink(),
  });

  useEffect(() => {
    sendAnalyticsEvent('EMC Information page opened', {
      TravellerID: emcDeclaring?.travellerId ?? '',
    });
  }, []);

  return (
    <div>
      {/*EMC Cover Policy Description*/}
      <div>
        <Text className="mt-2 mb-1" variant="body-16/sb">
          {i18n.t('emcShared.information.subtitle')}
        </Text>
        <Text className="pb-9" variant="body-16/r" withMarkDown>
          {i18n.t('emcShared.information.description')}
        </Text>
      </div>
      {/* EMC Accordion Information */}
      <div className="md:mb-20">
        {listItems.map((item, index, array) => {
          const isLast = index === array.length - 1;
          const isFirst = index === 0;
          return (
            <AccordionItem
              key={`${index}${item.title}`}
              className={twMerge(isFirst ? 'rounded-t-lg' : '', isLast ? 'border-b-[1px]' : '')}
              titleChildren={<Text variant="body-16/sb">{item.title}</Text>}>
              <EmcAccordionContent content={item.content} />
            </AccordionItem>
          );
        })}
      </div>
      <div className="relative bottom-0 left-0 w-full bg-mono-100 py-4">
        <div className="flex flex-col md:flex-row items-center gap-3 md:justify-end">
          <Button
            className={twMerge(
              'w-full md:w-auto md:min-w-[22rem]',
              isInfoIcon ? 'hidden' : 'mt-10',
            )}
            variant="charcoal"
            onClick={() => {
              setOpenEmcSection('pre-assessment');
            }}>
            {i18n.t('emcShared.information.button')}
          </Button>
          <EmcNeedHelp className={twMerge('sm:hidden mt-3 mb-8', isInfoIcon ? 'mt-10' : '')} />
        </div>
      </div>
    </div>
  );
};
