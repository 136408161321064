import React from 'react';

import { colors } from 'freely-shared-design';

import { Assets } from '@assets';
import { Container, Footer, Text } from '@components';
import { useNavigateToFreely } from '@hooks';

export const NotFound: React.FC = () => {
  const { navigateToFreelyWebsite } = useNavigateToFreely();

  return (
    <div>
      <header>
        <div className="bg-cabo-50 flex h-12 items-center gap-4 px-3.5 md:h-20 md:gap-12 md:px-6 lg:h-24 lg:gap-48">
          <button onClick={() => navigateToFreelyWebsite}>
            <Assets.FreelyPrime className="w-16 md:w-20 lg:w-40 " fill={colors.fuji[800]} />
          </button>
        </div>
      </header>
      <main className="text-center">
        <Container>
          <div className="mt-10 mb-12 flex justify-center 2xl:mt-24">
            <Assets.GeoLocation />
          </div>
          <Text variant="h1-44/sb" className="mb-8">
            Not all those who wander are lost...
          </Text>
          <Text variant="subTitle-20/r" className="mt-10 block">
            ...but in this case you are 100% lost.
            <br /> Try another page or hit the home button below and you&apos;ll be right back on
            track...
          </Text>
          <a
            href="/"
            className="bg-nusa-200 text-fuji-800 hover:bg-nusa-100 mt-12 mb-8 inline-block rounded-full px-10 py-3 transition-colors 2xl:mb-32">
            <Text variant="subTitle-20/m">Return Home</Text>
          </a>
        </Container>
      </main>
      <Footer isNotFound />
    </div>
  );
};
