import { ReactNode } from 'react';
import { shallow } from 'zustand/shallow';

import { colors } from 'freely-shared-design';
import { useConfigStore, useRegionStore } from 'freely-shared-stores';
import { getDurationOfDatesInDays, getRegionDateTime } from 'freely-shared-utils';

import { useTripStore } from '@store';
import { Elements } from '@stripe/react-stripe-js';
import { StripeElementsOptions, loadStripe } from '@stripe/stripe-js';

const DEFAULT_PAYMENT_TYPES = ['card'];
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY);

function useGetStripeOptions() {
  const country = useRegionStore(state => state.region?.country ?? 'AU');
  const tripCostInCents = useTripStore(state => {
    return parseFloat(String(state.trip?.price)) ?? '100';
  });
  const isFreeOfCharge = useTripStore(state => state.trip?.promotionCode?.isFreeOfCharge);
  const tripEndDate = useTripStore(state => state.trip?.endDate);
  const [afterPayMaxAmountLimit = 0, afterPayEndDateOffset = 0] = useConfigStore(
    state => [
      state.regionSpecificConfig?.RULES?.AFTER_PAY_MAX_AMOUNT_LIMIT,
      state.regionSpecificConfig?.RULES?.AFTER_PAY_TRIP_END_DATE_OFFSET,
    ],
    shallow,
  );
  const daysToTripEndDate = getDurationOfDatesInDays(
    getRegionDateTime(country),
    tripEndDate,
    country,
  );
  const isTripEndDateWithinAfterPayEndDateOffset = daysToTripEndDate < afterPayEndDateOffset;
  const isAfterPayEnabled =
    tripCostInCents <= afterPayMaxAmountLimit * 100 && isTripEndDateWithinAfterPayEndDateOffset;

  const paymentMethodTypes = [...DEFAULT_PAYMENT_TYPES];

  if (isAfterPayEnabled) {
    paymentMethodTypes.push('afterpay_clearpay');
  }

  return {
    mode: 'payment',
    //we are setting $1 for 100% discount to make stripe work, we will not use it for payment
    amount: isFreeOfCharge ? 100 : tripCostInCents,
    currency: 'aud',
    locale: 'en-AU',
    payment_method_types: paymentMethodTypes,
    capture_method: 'manual',
    appearance: {
      theme: 'flat',
      variables: {
        colorPrimary: colors.mono[100],
        borderRadius: '9999px',
        colorDanger: colors.red[500],
        colorText: colors.fuji[800],
        colorBackground: colors.cabo[50],
        colorIcon: colors.fuji[800],
      },
      rules: {
        '.Tab': {
          border: `2px solid ${colors.cabo[50]}`,
          borderRadius: '0.5rem',
        },
        '.Tab--selected': {
          border: `2px solid ${colors.fuji[800]}`,
        },
        '.Block': {
          borderRadius: '0.5rem',
        },
      },
    },
  } satisfies StripeElementsOptions;
}

export const StripePaymentElementProvider = ({ children }: { children: ReactNode }) => {
  const options = useGetStripeOptions();
  return (
    <Elements stripe={stripePromise} options={options}>
      {children}
    </Elements>
  );
};
