import { useFormContext } from 'react-hook-form';

import { PaymentOption, US_PAYMENT_TOKEN_TYPE } from 'freely-shared-types';
import { convertCentsToDollars, logToSentry } from 'freely-shared-utils';

import { Button, Text } from '@components';
import GooglePayButton from '@google-pay/button-react';
import { CheckoutFormUS, useApplePay, useCheckoutFlow, useGooglePay } from '@hooks';
import { i18n } from '@i18n';
import { useCheckoutStore, useGuestStore, useTripStore } from '@store';
import {
  onProcessingPaymentModal,
  sendAnalyticsEvent,
  sendAnalyticsUserId,
  testProps,
} from '@utils';

export const TotalPriceButton = () => {
  const { onApplePayButtonClicked } = useApplePay();
  const { googlePayButtonProps } = useGooglePay();
  const { onCheckoutProgress } = useCheckoutFlow();
  const { handleGooglePayment } = useGooglePay();
  const updateGuest = useGuestStore(state => state.updateGuest);

  const { updateTripPolicyStatus, purchaseUsTrip, tripCost } = useTripStore(state => ({
    updateTripPolicyStatus: state?.updateTripPolicyStatus,
    purchaseUsTrip: state?.purchaseUsTrip,
    tripCost: state.trip?.price,
  }));

  const { paymentSectionType, openSection, isLegalConsentChecked, googlePaymentToken } =
    useCheckoutStore(state => ({
      paymentSectionType: state.paymentSectionType ?? 'card',
      openSection: state.openSection,
      isLegalConsentChecked: state.isLegalConsentChecked,
      googlePaymentToken: state.googleCreditCardDetails?.paymentToken,
    }));

  //Credit card details
  const {
    getValues,
    setError,
    formState: { isValid },
  } = useFormContext<CheckoutFormUS>();
  const { cardNumber, expiryDate, nameOnCard, cardType } = getValues('cardDetails');

  const handleUSPaymentSubmit = async () => {
    try {
      // set policy status to Normal to not display errors banner each time after resubmit
      // and also payment processing modal will fire off to continue payment process
      updateTripPolicyStatus({ policyStatus: 'NORMAL' });
      if (cardType) {
        const guestDetails = await updateGuest();
        sendAnalyticsUserId(guestDetails?.userId ?? null, {
          email: guestDetails?.email?.toLowerCase(),
        });

        const cleanCreditCard = cardNumber?.replace(/ /g, '');
        const paymentOption: PaymentOption = {
          tokenType: US_PAYMENT_TOKEN_TYPE.CREDIT_CARD,
          cardNumber: cleanCreditCard,
          expiryDate: expiryDate,
          nameOnCard: nameOnCard,
          type: cardType,
        };

        await purchaseUsTrip(paymentOption);
        onProcessingPaymentModal();
      }
    } catch (err: any) {
      if (err?.message?.includes('Invalid Card')) {
        setError('cardDetails.cardNumber', { message: 'Your card number is invalid.' });
      } else {
        updateTripPolicyStatus({ policyStatus: 'ERROR' });
      }
      logToSentry(err);
    }
  };

  const onClick = async () => {
    await onCheckoutProgress(undefined, async () => {
      // Prevent the user from proceeding with the payment if:
      // 1. They are not on the 'payment' section.
      // 2. The legal consent checkbox is not checked.
      if (openSection !== 'payment' || !isLegalConsentChecked) {
        return;
      }

      // check if the form is valid for card payment
      if (!isValid && paymentSectionType === 'card') {
        return;
      }

      // Google Pay Call
      if (paymentSectionType === 'googlepay' && googlePaymentToken) {
        return handleGooglePayment();
      }

      sendAnalyticsEvent('Pay Button Clicked');

      return await handleUSPaymentSubmit();
    });
  };
  // Apple Pay button
  if (paymentSectionType === 'applepay' && openSection === 'payment') {
    return (
      <div className="w-full">
        <button
          className="h-10 apple-pay-button apple-pay-button-fuji-800 w-full active:opacity-80 hover:opacity-80 cursor-pointer"
          onClick={onApplePayButtonClicked}></button>
      </div>
    );
  }
  // Google Pay button
  if (paymentSectionType === 'googlepay' && openSection === 'payment' && !googlePaymentToken) {
    return (
      <div className="w-full">
        <GooglePayButton {...googlePayButtonProps} />
      </div>
    );
  }

  return (
    <Button
      {...testProps('text_buy_now_button')}
      variant="charcoal"
      onClick={onClick}
      className="w-full">
      <Text variant="subHeading-14/sb">
        {openSection === 'payment'
          ? i18n.t('checkout.payment.button.pay', { amount: convertCentsToDollars(tripCost) })
          : 'Continue'}
      </Text>
    </Button>
  );
};
