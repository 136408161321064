import { twMerge } from 'tailwind-merge';

import { colors } from 'freely-shared-design';
import { BOOST_CATEGORY, Boost } from 'freely-shared-types';

import { Assets } from '@assets';
import { Button, Text } from '@components';
import { i18n } from '@i18n';
import { ROUTE_PATHS, router } from '@routes/router';
import { getTripSelector, useTripStore } from '@store';

export interface BenefitsAndExcessProps {
  onClick: () => void;
  hasFullBenefitsDisplayed?: boolean;
}

export const BenefitsAndExcess: React.FC<BenefitsAndExcessProps> = ({
  onClick,
  hasFullBenefitsDisplayed,
}) => {
  const trip = useTripStore(getTripSelector);
  const essentialBoost =
    trip.boosts.find(boost => boost.category === BOOST_CATEGORY.MY_HEALTH) ?? ({} as Boost);

  return (
    <div className="lg:grid lg:grid-cols-4 justify-items-center md:grid md:grid-cols-5 relative flex justify-between items-center w-full flex-col-reverse">
      <div className="col-span-1">
        {hasFullBenefitsDisplayed && (
          <Assets.ArrowUp
            fill={colors.fuji[800]}
            width="32px"
            height="32px"
            onClick={onClick}
            className="absolute bottom-3 left-0 cursor-pointer bg-slate-100 p-2 rounded-full"
          />
        )}
      </div>
      <div className="lg:col-span-2 md:col-span-3">
        <Button
          className={twMerge('w-60 h-11', hasFullBenefitsDisplayed && 'w-52')}
          variant={hasFullBenefitsDisplayed ? 'mint' : 'snow'}
          onClick={async () => {
            if (!hasFullBenefitsDisplayed) {
              return onClick();
            }
            router.navigate(ROUTE_PATHS().boostDetails(essentialBoost?.boostId));
          }}>
          <Text variant="body-16/sb">
            {hasFullBenefitsDisplayed
              ? i18n.t('myQuote.overseasBenefits.actions.policy')
              : i18n.t('myQuote.overseasBenefits.actions.benefits')}
          </Text>
        </Button>
      </div>
    </div>
  );
};
