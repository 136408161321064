import React from 'react';

import { colors } from 'freely-shared-design';

import { Assets } from '@assets';
import { useNavigateToFreely } from '@hooks';
import { i18n } from '@i18n';
import { router } from '@routes/router';
import { testProps } from '@utils';

import { Text } from '../text';

const BoostTopHeader: React.FC = () => {
  const { openRedirectToFreelyModal } = useNavigateToFreely();

  return (
    <header className="bg-cabo-50">
      <div className="entry-content mx-auto flex items-center justify-between py-4 px-4 md:px-8">
        <div className="flex hover:cursor-pointer" onClick={() => router.navigate(-1)}>
          <Assets.ArrowLeftAlt className="relative top-px" />
          <Text {...testProps('back-to-trip-extras')} variant="body-16/m" className="ml-2">
            {i18n.t('global.actions.back')}
          </Text>
        </div>
        <button
          {...testProps('open-redirect-to-freely-modal-button')}
          onClick={openRedirectToFreelyModal}
          className="lg:[&>svg]:h-12 lg:[&>svg]:w-24">
          <Assets.FreelyPrime fill={colors.fuji[800]} />
        </button>
      </div>
    </header>
  );
};

export default BoostTopHeader;
