import { AccordionContainer, ExcessAmount, Text } from '@components';
import { i18n } from '@i18n';
import { useBoostDetailsStore } from '@store';

export const Benefits = () => {
  const boostMessage = useBoostDetailsStore(
    state => state.mandatoryBoostDetails?.template?.boostMessage,
  );
  const title = useBoostDetailsStore(state => state.mandatoryBoostDetails?.template?.title);
  return (
    <div>
      <div className="flex flex-row items-center flex-wrap mb-4">
        <Text
          variant={{
            lg: 'h3-28/sb',
            md: 'h3-28/sb',
            sm: 'subTitle-20/sb',
          }}
          className="pr-1 md:pr-2">
          {title ?? ''}
        </Text>
        <Text variant={{ lg: 'h4-24/r', sm: 'body-16/r' }}>
          {i18n.t('myQuote.benefitsWhatsIncluded')}
        </Text>
        <ExcessAmount className="hidden md:flex md:ml-auto" />
      </div>
      <Text variant="body-16/r" className="mb-4 md:mb-8">
        {boostMessage}
      </Text>
      <ExcessAmount className="mb-8 md:hidden" />
      <AccordionContainer />
    </div>
  );
};
