import { Fragment } from 'react';

import {
  FullScreenModalBody,
  FullScreenModalBodyProps,
  FullScreenModalComponentName,
} from '@components';
import { Dialog, Transition } from '@headlessui/react';

export interface FullScreenModalProps {
  body?: string | FullScreenModalBodyProps<FullScreenModalComponentName>;
  children?: React.ReactNode;
}

// For all full screens modal. At the moment it is blue
export const FullScreenModal = ({ body, children }: FullScreenModalProps) => {
  return (
    <Transition.Root>
      <Dialog as="div" className="relative z-10" onClose={v => v}>
        <div className="fixed inset-0 overflow-y-auto">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95">
            <Dialog.Panel
              className={'w-[100vw] h-[100vh] flex items-center justify-center bg-nusa-200 px-6'}>
              <FullScreenModalBody
                {...(body as FullScreenModalBodyProps<FullScreenModalComponentName>)}
              />
            </Dialog.Panel>
          </Transition.Child>
        </div>
        {children}
      </Dialog>
    </Transition.Root>
  );
};
