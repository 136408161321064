import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { twMerge } from 'tailwind-merge';

import { colors } from 'freely-shared-design';
import { useConfigStore } from 'freely-shared-stores';
import { TripCostForm } from 'freely-shared-types';
import { getCurrencySymbol, logToSentry } from 'freely-shared-utils';

import { Assets } from '@assets';
import { usePriceChangeModal } from '@hooks';
import { i18n } from '@i18n';
import { closeModalSelector, openModalSelector, useModalStore, useTripStore } from '@store';
import { sendAnalyticsEvent, testProps } from '@utils';

import { Button } from '../button';
import { Container } from '../container';
import { GetQuoteProvider } from '../getQuoteProvider';
import { Text } from '../text';
import { TripCostInput } from '../tripCostInput';

const TAG = 'TripCostEditor';

export type TripCostEditorProps = {
  isModal?: boolean;
  onNext: () => Promise<void> | void;
  onBack: () => void;
};

export const TripCostEditor = (props: TripCostEditorProps) => {
  const totalTripCost = useTripStore(state => {
    const tripCost = state?.trip?.totalTripCost;
    return tripCost ? tripCost.toString() : '';
  });
  return (
    <GetQuoteProvider defaultFormValues={{ totalTripCost }} mode="all" reValidateMode="onChange">
      {<TripCostEditorContent {...props} />}
    </GetQuoteProvider>
  );
};

export const TripCostEditorContent: React.FC<TripCostEditorProps> = ({
  isModal,
  onNext,
  onBack,
}) => {
  const {
    formState: { isValid },
    handleSubmit,
  } = useFormContext<TripCostForm>();
  const { openPriceChangeModal } = usePriceChangeModal();
  const setTotalTripCost = useTripStore(state => state.setTotalTripCost);
  const regionSpecificConfig = useConfigStore(state => state?.regionSpecificConfig);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const syncTrip = useTripStore(state => state.syncTrip);
  const closeModal = useModalStore(closeModalSelector);
  const VALUE_PREFIX: string | undefined = useMemo(
    () => getCurrencySymbol(regionSpecificConfig),
    [regionSpecificConfig],
  );

  const isButtonDisabled = !isValid || isLoading;

  useEffect(() => {
    if (isModal) {
      sendAnalyticsEvent('Modal viewed', { modalTitle: i18n.t('tripCost.modal.title') });
    }
  }, [isModal]);

  const handleNext = async (values: TripCostForm) => {
    const totalTripCost = parseInt(
      values.totalTripCost.replace(VALUE_PREFIX ?? '', '').replace(/,/g, ''),
      10,
    );

    if (!totalTripCost) {
      return;
    }

    setTotalTripCost(totalTripCost);

    if (isModal) {
      return openPriceChangeModal({
        onNext: async () => {
          setIsLoading(true);
          setTotalTripCost(totalTripCost);
          onNext();
          await syncTrip();
          closeModal();
          setIsLoading(false);
        },
      });
    }

    try {
      setIsLoading(true);
      await onNext();
    } catch (err) {
      logToSentry(err as Error, { tag: TAG });
    } finally {
      setIsLoading(false);
    }
  };

  const openModal = useModalStore(openModalSelector);
  const handleInfoButtonClick = () => {
    return openModal('Modal', {
      title: i18n.t('tripCost.modal.title'),
      bodyTextProps: { children: i18n.t('tripCost.modal.content') },
      actions: [
        {
          children: i18n.t('tripCost.modal.actions.ok'),
          variant: 'mint',
        },
      ],
    });
  };

  return (
    <Container
      titleText={i18n.t('tripCost.title')}
      subtitleText={i18n.t('tripCost.subtitle')}
      className="flex flex-1 flex-col">
      <form>
        <div className="mx-auto w-full max-w-lg">
          <TripCostInput prefixValue={VALUE_PREFIX} isModal={isModal} />

          <div className="mx-auto max-w-2xl flex-1 space-y-4 py-6 text-center">
            <Text variant="body-16/r" className="mx-auto w-3/4">
              {i18n.t('tripCost.description')}
            </Text>
            <div className="mx-auto flex flex-row justify-center pt-16 pb-2">
              <Assets.InfoCircle
                cursor="pointer"
                fill={colors.fuji[300]}
                height={24}
                width={24}
                className="mx-2 ml-2  align-middle"
                onClick={handleInfoButtonClick}
              />
              <Text variant="body-16/r">{i18n.t('tripCost.modal.title')}</Text>
            </div>

            <div
              className={twMerge(
                'mt-6 flex items-center gap-4 border-t pt-6',
                isModal ? 'flex-col-reverse' : 'flex-col',
                !isModal && 'mt-6 flex w-full flex-col items-center gap-4 border-t pt-6',
              )}>
              <Button
                {...testProps('trip-cost-submit-button-save-change-or-get-quote')}
                onClick={handleSubmit(handleNext)}
                type="submit"
                isJumboSize={!isModal}
                variant="mint"
                size="md"
                disabled={isButtonDisabled}
                isLoading={isLoading}
                className={twMerge(isModal && 'w-full')}>
                {isModal ? i18n.t('tripCost.actions.save') : i18n.t('global.actions.next')}
              </Button>
              <Button
                {...testProps('trip-cost-cancel-or-back-button')}
                className={'w-full'}
                variant="snow"
                size="md"
                onClick={onBack}>
                {isModal ? i18n.t('global.actions.cancel') : i18n.t('global.actions.back')}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </Container>
  );
};
