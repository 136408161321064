import { useEffect } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { twMerge } from 'tailwind-merge';

import { dobFormat } from 'freely-shared-constants';
import {
  getRegionSelector,
  regionDateUtils,
  useConfigStore,
  useRegionStore,
} from 'freely-shared-stores';
import { GuestDetailsForm } from 'freely-shared-types';
import {
  dateToUTCFormat,
  getTravellerAgeType,
  secondaryAgeValidation,
  shouldRevalidateDependencyFlag,
  utcToDateFormat,
  validate,
  validateDate,
} from 'freely-shared-utils';

import { Assets } from '@assets';
import { Checkbox, CoveredCard, Input, MultiConditions, Text } from '@components';
import { useEmcTraveller } from '@hooks';
import { i18n } from '@i18n';
import { useEmcStore } from '@store';
import { shouldShowConfirmAssessmentOnNameChange, showEmcCoveredCard, testProps } from '@utils';

import { EmcCheckRadioGroup } from './emcCheckRadioGroup.component';
import { EmcConfirmAssessment } from './emcConfirmAssessment.component';

type Props = {
  onFormValidate?: (isValid: boolean) => void;
};

export const SecondaryTravellersForm: React.FC<Props> = ({ onFormValidate }) => {
  const {
    control,
    register,
    watch,
    formState: { errors, isValid },
    setValue,
    getValues,
  } = useFormContext<GuestDetailsForm>();
  const { fields } = useFieldArray({
    control,
    name: 'secondaryTravellers',
  });

  const regionSpecificConfig = useConfigStore(state => state?.regionSpecificConfig);
  const isDependantCheckboxEnabled = regionSpecificConfig?.RULES.RESIDENT_CHECKBOX ?? false;
  const region = useRegionStore(getRegionSelector);
  const secondaryTravellerAgeRules = regionSpecificConfig?.RULES.SECONDARY_TRAVELLER_AGE ?? {
    min: 0,
    mid: { min: 18, max: 21 },
    max: 99,
  };
  const setNameChangedTravellers = useEmcStore(state => state.setNameChangedTravellers);
  const removeNameChangedTraveller = useEmcStore(state => state.removeNameChangedTraveller);
  const secondaryTravellerDependencyMaxAge =
    regionSpecificConfig?.RULES.SECONDARY_TRAVELLER_AGE?.mid?.max ?? 20;
  const secondaryTravellerDependencyMinAge =
    regionSpecificConfig?.RULES.SECONDARY_TRAVELLER_AGE?.mid?.min ?? 18;

  const { getEmcTravellerById } = useEmcTraveller();
  const dateOfBirthLabel = i18n.t('travellerForm.dobInput.label');

  useEffect(() => {
    onFormValidate?.(isValid);
  }, [isValid, onFormValidate]);

  return (
    <>
      {fields.map((field, index) => {
        const dobInputId = `secondaryTravellers.${index}.dob` as never;
        const firstNameInputId = `secondaryTravellers.${index}.firstName` as never;
        const lastNameInputId = `secondaryTravellers.${index}.lastName` as never;
        const secondaryTravellerErrors = errors.secondaryTravellers?.[index];
        const firstNameError = secondaryTravellerErrors?.['firstName'];
        const lastNameError = secondaryTravellerErrors?.['lastName'];
        const dobError = secondaryTravellerErrors?.['dob'];
        const age = regionDateUtils().getAge(watch(dobInputId) as never);
        const travellerType = getTravellerAgeType(age, secondaryTravellerAgeRules);
        const isYoungAdult = travellerType === 'Young Adult';
        const isChild = travellerType === 'Child';
        const shouldDisplayDependencyCheckBox =
          isDependantCheckboxEnabled && (isYoungAdult || isChild);
        const emcSecondaryTraveller = getEmcTravellerById(field.sortKey ?? '');

        return (
          <div
            className={twMerge(
              'border-y md:border-none md:pt-0 pt-8 mb-8',
              index === 0 && 'mt-8 border-b-0',
            )}
            key={field.sortKey}>
            <div className="flex justify-between">
              <div className="flex gap-2">
                <Text variant="h4-24/sb">
                  {i18n.t('travellerForm.additionalTravellers.title', { count: index + 1 })}
                </Text>
                {!dobError ? (
                  <Text className="text-fuji-300" variant="h4-24/r">
                    {i18n.t('travellerForm.ageLabel', { age })}
                  </Text>
                ) : null}
              </div>
            </div>
            <div className={'flex flex-col gap-2 my-4 lg:flex-row'}>
              <div className="flex-1">
                <Input
                  isSuccess={!!watch(firstNameInputId) && !firstNameError}
                  hasError={!!firstNameError}
                  errorMessage={firstNameError?.message}
                  labelProps={{ children: i18n.t('travellerForm.firstNameInput.label') }}
                  placeholder={i18n.t('travellerForm.firstNameInput.placeholder')}
                  {...register(firstNameInputId, {
                    validate: v => validate('firstName', v ?? '') ?? undefined,
                  })}
                  onBlur={e => {
                    setValue(`secondaryTravellers.${index}.firstName`, e.target.value.trim());
                    if (!emcSecondaryTraveller) return;
                    if (
                      shouldShowConfirmAssessmentOnNameChange(
                        {
                          firstName: e.target.value.trim(),
                          lastName: getValues(`secondaryTravellers.${index}.lastName`) ?? '',
                        },
                        emcSecondaryTraveller,
                      )
                    ) {
                      setNameChangedTravellers(emcSecondaryTraveller?.travellerId);
                    } else {
                      removeNameChangedTraveller(emcSecondaryTraveller?.travellerId);
                    }
                  }}
                />
              </div>
              <div className="flex-1">
                <Input
                  isSuccess={!!watch(lastNameInputId) && !lastNameError}
                  hasError={!!lastNameError}
                  errorMessage={lastNameError?.message}
                  labelProps={{ children: i18n.t('travellerForm.lastNameInput.label') }}
                  placeholder={i18n.t('travellerForm.lastNameInput.placeholder')}
                  {...register(lastNameInputId, {
                    validate: v => validate('lastName', v ?? '') ?? undefined,
                  })}
                  onBlur={e => {
                    setValue(`secondaryTravellers.${index}.lastName`, e.target.value.trim());
                    if (!emcSecondaryTraveller) return;
                    if (
                      shouldShowConfirmAssessmentOnNameChange(
                        {
                          firstName: getValues(`secondaryTravellers.${index}.firstName`) ?? '',
                          lastName: e.target.value.trim(),
                        },
                        emcSecondaryTraveller,
                      )
                    ) {
                      setNameChangedTravellers(emcSecondaryTraveller?.travellerId);
                    } else {
                      removeNameChangedTraveller(emcSecondaryTraveller?.travellerId);
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2 mb-4 md:flex-row">
              <div className="flex-1">
                <Controller
                  control={control}
                  name={dobInputId}
                  rules={{
                    validate: {
                      validDate: v => validateDate(v ?? ''),
                      validateAge: v =>
                        secondaryAgeValidation(v ?? '', {
                          min: 0,
                          max: regionSpecificConfig?.RULES.SECONDARY_TRAVELLER_MAX_AGE,
                          country: region?.country,
                        }),
                    },
                  }}
                  render={({ field }) => (
                    <PatternFormat
                      inputMode="numeric"
                      customInput={Input}
                      format={dobFormat}
                      mask="_"
                      hasError={!!dobError}
                      errorMessage={dobError?.message}
                      isSuccess={watch(dobInputId) && !dobError}
                      onChange={e => {
                        const formattedDate = dateToUTCFormat(e.target.value, region?.country);
                        field.onChange(formattedDate);

                        if (
                          shouldRevalidateDependencyFlag(formattedDate, isDependantCheckboxEnabled)
                        ) {
                          const age = regionDateUtils().getAge(formattedDate);
                          const isYoungAdult =
                            age >= secondaryTravellerDependencyMinAge &&
                            age <= secondaryTravellerDependencyMaxAge;
                          const isChild = age < secondaryTravellerDependencyMinAge;
                          const isAdult = age > secondaryTravellerDependencyMaxAge;
                          if (isYoungAdult) {
                            setValue(`secondaryTravellers.${index}.isDependant`, false);
                            return;
                          }
                          if (isAdult) {
                            setValue(`secondaryTravellers.${index}.isDependant`, false);
                            return;
                          }
                          if (isChild) {
                            setValue(`secondaryTravellers.${index}.isDependant`, false);
                            return;
                          }
                        }
                      }}
                      onBlur={field.onBlur}
                      value={utcToDateFormat(field.value, region?.country)}
                      labelProps={{ children: dateOfBirthLabel }}
                      placeholder={i18n.t('travellerForm.dobInput.placeholder')}
                      svgIcon={
                        <Assets.Calendar
                          className={twMerge('text-gray-400', !!dobError && '!text-red-300')}
                        />
                      }
                    />
                  )}
                />
              </div>
              <div className="flex-1" />
            </div>
            {shouldDisplayDependencyCheckBox && !dobError && (
              <Controller
                name={`secondaryTravellers.${index}.isDependant`}
                control={control}
                render={({ field: { onChange } }) => {
                  return (
                    <Checkbox
                      isChecked={watch(`secondaryTravellers.${index}.isDependant`)}
                      onClick={() => onChange(!watch(`secondaryTravellers.${index}.isDependant`))}
                      {...testProps(`this-traveller-is-dependant-checkbox`)}
                      labelProps={{
                        children: i18n.t('checkout.text_addTraveller_checkbox_dependant_des'),
                        className: 'text-body',
                      }}
                    />
                  );
                }}
              />
            )}
            {emcSecondaryTraveller && showEmcCoveredCard(emcSecondaryTraveller) ? (
              <CoveredCard
                variant={emcSecondaryTraveller?.isAdded ? 'mint' : 'cherry'}
                className="my-4">
                <MultiConditions
                  travellerId={emcSecondaryTraveller?.travellerId}
                  age={age}
                  firstName={getValues(`secondaryTravellers.${index}.firstName`)}
                  lastName={getValues(`secondaryTravellers.${index}.lastName`)}
                />
              </CoveredCard>
            ) : (
              <EmcCheckRadioGroup
                firstName={watch(`secondaryTravellers.${index}.firstName`)}
                lastName={watch(`secondaryTravellers.${index}.lastName`)}
                sortKey={watch(`secondaryTravellers.${index}.sortKey`)}
                dob={watch(`secondaryTravellers.${index}.dob`) ?? ''}
              />
            )}
            <EmcConfirmAssessment
              travellerId={emcSecondaryTraveller?.travellerId}
              age={age}
              firstName={getValues(`secondaryTravellers.${index}.firstName`) ?? ''}
              lastName={getValues(`secondaryTravellers.${index}.lastName`) ?? ''}
            />
          </div>
        );
      })}
    </>
  );
};
