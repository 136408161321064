import kebabCase from 'lodash/kebabCase';
import { shallow } from 'zustand/shallow';

import { notifications } from 'freely-shared-constants';
import { colors } from 'freely-shared-design';
import { TripDestination } from 'freely-shared-types';

import { Assets } from '@assets';
import { Button, NotificationVariantTypes, TextProps } from '@components';
import { i18n } from '@i18n';
import { useFeatureFlagsStore } from '@store';
import { testProps } from '@utils';

import { Notification } from '../notification';

export interface SelectedDestinationProps {
  selectedDestinations: TripDestination[];
  onRemoveSelectedDestination: (destination: TripDestination) => void;
  hasReachedMaxDestinations: boolean;
}

export const SelectedDestination: React.FC<SelectedDestinationProps> = ({
  selectedDestinations,
  onRemoveSelectedDestination,
  hasReachedMaxDestinations,
}) => {
  const { headingProps, descriptionProps, variant } = useGetDestinationNotificationProps({
    hasReachedMaxDestinations,
    selectedDestinations,
  });

  if (selectedDestinations.length === 0) return null;

  return (
    <div className="my-4 border-b-2 pb-8 text-center">
      <ul role="list" className="grid grid-cols-2 gap-4 md:grid-cols-3 lg:grid-cols-4">
        {selectedDestinations.map(destination => (
          <li key={`${destination.countryCode}-${destination.longName}`} className="col-span-1">
            <Button
              {...testProps(
                `selected-country-destination-button-${kebabCase(destination.longName)}`,
              )}
              withEllipsis
              title={destination.countryName}
              size="md"
              IconRight={Assets.CloseCircleLight}
              IconRightProps={{ fill: colors.fuji[800] }}
              textAlign="left"
              fontVariant="body-16/m"
              variant="mint"
              onClick={() => onRemoveSelectedDestination(destination)}
              className="w-full text-left">
              {destination.countryName ?? destination.longName}
            </Button>
          </li>
        ))}
      </ul>
      <Notification
        variant={variant}
        headingProps={headingProps}
        descriptionProps={descriptionProps}
        className="mt-4"
      />
    </div>
  );
};

function useGetDestinationNotificationProps({
  selectedDestinations,
  hasReachedMaxDestinations,
}: Omit<SelectedDestinationProps, 'onRemoveSelectedDestination'>) {
  const [isFeatureEnabled, featurePayload] = useFeatureFlagsStore(
    state => [
      !!state?.featureFlags?.['cruise-cover-notification']?.isEnabled,
      state?.featureFlags?.['cruise-cover-notification']?.payload,
    ],
    shallow,
  );

  const notificationType = hasReachedMaxDestinations ? 'maxDestination' : 'stoppingOver';
  const props: {
    headingProps: TextProps;
    descriptionProps: TextProps;
    variant: NotificationVariantTypes;
  } = {
    headingProps: {
      children: i18n.t(notifications[notificationType].title),
    },
    descriptionProps: {
      children: i18n.t(notifications[notificationType].description),
    },
    variant: hasReachedMaxDestinations ? 'warning' : 'info',
  };
  if (!isFeatureEnabled || !featurePayload) {
    return props;
  }

  const hasCruiseNotification = selectedDestinations.some(it =>
    featurePayload?.cruiseCountryCodes.includes(it.countryCode),
  );

  if (hasCruiseNotification && !hasReachedMaxDestinations) {
    props.headingProps = {
      children: featurePayload.title,
    };
    props.descriptionProps = {
      children: featurePayload.subtitle,
    };
    props.variant = 'warning';
    return props;
  }

  return props;
}
